/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third party
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonTertiary from 'components/elements/Buttons/ButtonTertiary'

// Images
import Download from 'img/download.inline.svg'

const Section = styled.section`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.primary};
  margin-bottom: 6rem;

  & svg {
    width: 35px;
    height: 35px;
  }

  & a {
    font-weight: 400 !important;

    &:hover {
      & svg path {
        fill: white;
      }
    }
  }
`

const SVGWrapper = styled.div`
  & span {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
  & svg {
    width: 35px;
    height: 35px;
  }
`

interface DownloadsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Downloads
}

const Downloads: React.FC<DownloadsProps> = ({ fields }) => (
  <Section>
    <div className="container py-5">
      <ParseContent content={fields.description} className="mb-5" />
      {fields.categories?.map((category, index) => (
        <div className="mb-5">
          <span key={index} className="font-weight-bold">
            {category?.title}
          </span>
          <div className="d-flex flex-column flex-lg-row mt-4">
            {category?.downloads?.map(({ download }: any, subIndex) => (
              <ButtonTertiary
                key={subIndex}
                to={download.url}
                className="me-5 mb-4"
                target="_blank"
              >
                <SVGWrapper className="d-flex align-items-center justify-content-center">
                  <span>{download.title}</span>
                  <Download className="ms-5" />
                </SVGWrapper>
              </ButtonTertiary>
            ))}
          </div>
        </div>
      ))}
    </div>
  </Section>
)

export default Downloads
